<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="show_modal"
    :click-to-close="false"
  >
    <!-- layer : 버튼 설정 -->
    <section id="modalResultBtn" class="layer_dialog modalin">
      <div class="clfix mb20">
        <h2 class="fl_l">버튼 설정</h2>
        <div class="tip_help_group fl_l">
          <button
            type="button"
            @click="tip_toggle = !tip_toggle"
            class="btn_help open_toggle"
          >
            <span class="material-icons">help</span>
          </button>
          <div v-if="tip_toggle === true" id="tip_help" class="tip_help">
            사용하시려는 버튼을 체크하여 노출할 수 있으며<br />노출하는 버튼명과
            컬러를 입력하여 사용이 가능합니다.
          </div>
        </div>
      </div>
      <ul class="setting_btn">
        <li>
          <dl>
            <dt>
              <input
                type="checkbox"
                name=""
                v-model="cart.checked"
                id="setBtn_1"
              /><label for="setBtn_1">장바구니 담기</label>
            </dt>
            <dd>
              <input
                id="basketSave"
                type="text"
                name=""
                v-model.trim="cart.text"
                placeholder="버튼명을 입력해주세요."
                style="width: 230px"
                autocomplete="off"
                @keyup="inputText($event)"
              />
            </dd>
            <dd class="colorbox">
              <div class="color">
                <span class="item ml10 mr10">버튼색상</span>
                <label for="btnBgCartColor"
                  ><input
                    type="text"
                    class="minicolors"
                    v-model="cart.button_color"
                    ref="minicolors[0]"
                    id="btnBgCartColor"
                    style="width: 100px"
                    autocomplete="off"
                    :maxlength="7"
                    @input="onlyHtmlColor($event, 'btnBgCartColor')"
                  /><span class="material-icons">color_lens</span></label
                >
                <span
                  class="color_preview_box"
                  :style="getBackgroundColor(cart.button_color)"
                ></span>

                <span class="item ml10 mr10">버튼 테두리 색상</span>
                <label for="btnBgCartBorderColor"
                  ><input
                    type="text"
                    class="minicolors"
                    v-model="cart.button_border_color"
                    ref="minicolors[0]"
                    id="btnBgCartBorderColor"
                    style="width: 100px"
                    autocomplete="off"
                    :maxlength="7"
                    @input="onlyHtmlColor($event, 'btnBgCartBorderColor')"
                  /><span class="material-icons">color_lens</span></label
                >
                <span
                  class="color_preview_box"
                  :style="getBackgroundColor(cart.button_border_color)"
                ></span>

                <span class="item ml10 mr10">글자색상</span>
                <label for="btnTextCartColor"
                  ><input
                    type="text"
                    class="minicolors"
                    v-model="cart.text_color"
                    ref="minicolors[1]"
                    id="btnTextCartColor"
                    style="width: 100px"
                    autocomplete="off"
                    :maxlength="7"
                    @input="onlyHtmlColor($event, 'btnTextCartColor')"
                  /><span class="material-icons">color_lens</span></label
                >
                <span
                  class="color_preview_box"
                  :style="getBackgroundColor(cart.text_color)"
                ></span>
              </div>
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>
              <input
                type="checkbox"
                name=""
                v-model="more.checked"
                id="setBtn_2"
              /><label for="setBtn_2">자세히보기</label>
            </dt>
            <dd>
              <input
                id="more_text"
                type="text"
                name=""
                v-model.trim="more.text"
                placeholder="버튼명을 입력해주세요."
                autocomplete="off"
                style="width: 230px"
                @keyup="inputText($event)"
              />
            </dd>
            <dd class="colorbox">
              <div class="color">
                <span class="item ml10 mr10">버튼색상</span>
                <label for="btnBgDtl"
                  ><input
                    type="text"
                    class="minicolors"
                    v-model="more.button_color"
                    ref="minicolors[2]"
                    id="btnBgDtl"
                    style="width: 100px"
                    autocomplete="off"
                    :maxlength="7"
                    @input="onlyHtmlColor($event, 'btnBgDtl')"
                  /><span class="material-icons">color_lens</span></label
                >
                <span
                  class="color_preview_box"
                  :style="getBackgroundColor(more.button_color)"
                ></span>

                <span class="item ml10 mr10">버튼 테두리 색상</span>
                <label for="btnBorderDtl"
                  ><input
                    type="text"
                    class="minicolors"
                    v-model="more.button_border_color"
                    ref="minicolors[0]"
                    id="btnBorderDtl"
                    style="width: 100px"
                    autocomplete="off"
                    :maxlength="7"
                    @input="onlyHtmlColor($event, 'btnBorderDtl')"
                  /><span class="material-icons">color_lens</span></label
                >
                <span
                  class="color_preview_box"
                  :style="getBackgroundColor(more.button_border_color)"
                ></span>

                <span class="item ml10 mr10">글자색상</span>
                <label for="btnTextDtl"
                  ><input
                    type="text"
                    class="minicolors"
                    v-model="more.text_color"
                    ref="minicolors[3]"
                    id="btnTextDtl"
                    style="width: 100px"
                    autocomplete="off"
                    :maxlength="7"
                    @input="onlyHtmlColor($event, 'btnTextDtl')"
                  /><span class="material-icons">color_lens</span></label
                >
                <span
                  class="color_preview_box"
                  :style="getBackgroundColor(more.text_color)"
                ></span>
              </div>
            </dd>
          </dl>
        </li>
        <li v-if="is_delivery">
          <dl>
            <dt>
              <input
                type="checkbox"
                name=""
                v-model="delivery.checked"
                id="setBtn_3"
              /><label for="setBtn_3">정기결제(배송)</label>
            </dt>
            <dd>
              <input
                type="text"
                name=""
                v-model.trim="delivery.text"
                placeholder="버튼명을 입력해주세요."
                style="width: 230px"
                autocomplete="off"
              />
            </dd>
            <dd class="colorbox">
              <div class="color">
                <span class="item ml10 mr10">버튼색상</span>
                <label for="btnBgPay"
                  ><input
                    type="text"
                    class="minicolors"
                    v-model="delivery.button_color"
                    ref="minicolors[4]"
                    id="btnBgPay"
                    style="width: 100px"
                    autocomplete="off"
                    :maxlength="7"
                    @input="onlyHtmlColor($event, 'btnBgPay')"
                  /><span class="material-icons">color_lens</span></label
                >
                <span
                  class="color_preview_box"
                  :style="getBackgroundColor(delivery.button_color)"
                ></span>
                <span class="item ml10 mr10">글자색상</span>
                <label for="btnTextPay"
                  ><input
                    type="text"
                    class="minicolors"
                    v-model="delivery.text_color"
                    ref="minicolors[5]"
                    id="btnTextPay"
                    style="width: 100px"
                    autocomplete="off"
                    :maxlength="7"
                    @input="onlyHtmlColor($event, 'btnTextPay')"
                  /><span class="material-icons">color_lens</span></label
                >
                <span
                  class="color_preview_box"
                  :style="getBackgroundColor(delivery.text_color)"
                ></span>
              </div>
            </dd>
          </dl>
        </li>
      </ul>
      <div class="btns">
        <ul>
          <li>
            <button
              type="button"
              @click="close"
              class="btn btn_large btn_secondary"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              @click="setResult"
              class="btn btn_large btn_primary"
            >
              <span>저장</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 버튼 설정  -->
  </vue-final-modal>
</template>

<script>
import common from '@/components/mixin/index.js';

export default {
  mixins: [common],
  inheritAttrs: false,
  data: () => ({
    show_modal: true,
    tip_toggle: false,
    cart: {
      checked: true,
      text: '',
      text_color: '',
      button_color: '',
      button_border_color: '',
    },
    more: {
      checked: true,
      text: '',
      text_color: '',
      button_color: '',
      button_border_color: '',
    },
    is_delivery: false,
    delivery: {
      checked: false,
      text: '',
      text_color: '',
      button_color: '',
    },
  }),
  props: ['survey_config_info', 'result'],
  created: function () {
    if (this.result.button) {
      const button = this.result.button;

      this.cart.checked = button.cart.checked;
      this.cart.text = button.cart.text;
      this.cart.button_color = button.cart.button_color;
      this.cart.text_color = button.cart.text_color;
      this.cart.button_border_color = button.cart.button_border_color;

      this.more.checked = button.more.checked;
      this.more.text = button.more.text;
      this.more.button_color = button.more.button_color;
      this.more.text_color = button.more.text_color;
      this.more.button_border_color = button.more.button_border_color;

      this.delivery.checked = button.delivery.checked;
      this.delivery.text = button.delivery.text;
      this.delivery.button_color = button.delivery.button_color;
      this.delivery.text_color = button.delivery.text_color;
    } else {
      this.cart.text = '장바구니 담기';
      this.cart.button_color = '#5e5e5e';
      this.cart.text_color = '#ffffff';
      this.cart.button_border_color = '#5e5e5e';
      this.more.text = '자세히보기';
      this.more.button_color = '#5e5e5e';
      this.more.text_color = '#ffffff';
      this.more.button_border_color = '#5e5e5e';
    }
  },
  computed: {},
  mounted() {
    this.$nextTick(function () {
      const self = this;

      $('#modalResultBtn .minicolors').minicolors({
        animationEasing: 'swing',
        keywords: '#',

        change(hex, opacity) {
          const id = $(this).prop('id');

          if (id === 'btnBgCartColor') self.cart.button_color = hex;
          if (id === 'btnTextCartColor') self.cart.text_color = hex;
          if (id === 'btnBgDtl') self.more.button_color = hex;
          if (id === 'btnTextDtl') self.more.text_color = hex;
          if (id === 'btnBgPay') self.delivery.button_color = hex;
          if (id === 'btnTextPay') self.delivery.text_color = hex;
          if (id === 'btnBgCartBorderColor')
            self.cart.button_border_color = hex;
          if (id === 'btnBorderDtl') self.more.button_border_color = hex;
        },
      });

      $('#btnBgCartColor').minicolors('value', this.cart.button_color);
      $('#btnTextCartColor').minicolors('value', this.cart.text_color);
      $('#btnBgDtl').minicolors('value', this.more.button_color);
      $('#btnTextDtl').minicolors('value', this.more.text_color);
      $('#btnBgPay').minicolors('value', this.delivery.button_color);
      $('#btnTextPay').minicolors('value', this.delivery.text_color);
      $('#btnBgCartBorderColor').minicolors(
        'value',
        this.cart.button_border_color,
      );
      $('#btnBorderDtl').minicolors('value', this.more.button_border_color);
    });
  },
  methods: {
    setResult: function () {
      let _param = this.result;
      let _set_button_param = {
        cart: {
          checked: this.cart.checked,
          text: this.cart.text,
          button_color: this.cart.button_color,
          text_color: this.cart.text_color,
          button_border_color: this.cart.button_border_color,
        },
        more: {
          checked: this.more.checked,
          text: this.more.text,
          button_color: this.more.button_color,
          text_color: this.more.text_color,
          button_border_color: this.more.button_border_color,
        },
        delivery: {
          checked: this.delivery.checked,
          text: this.delivery.text,
          button_color: this.delivery.button_color,
          text_color: this.delivery.text_color,
        },
      };

      this.cart.text = this.replaceHtml(this.cart.text).trim();
      this.more.text = this.replaceHtml(this.more.text).trim();
      // this.delivery.text = this.replaceHtml(this.delivery.text);

      if (this.cart.checked === true) {
        if (this.cart.text === '' || this.cart.text.length === 0) {
          alert('장바구니 버튼명을 입력해주세요.');
          return false;
        }
        if (
          this.cart.button_color === '' ||
          this.cart.button_color.length === 0
        ) {
          alert('장바구니 버튼색상을 입력해주세요.');
          return false;
        }
        if (this.cart.text_color === '' || this.cart.text_color.length === 0) {
          alert('장바구니 글자색상을 입력해주세요.');
          return false;
        }
        if (
          this.cart.button_border_color === '' ||
          this.cart.button_border_color.length === 0
        ) {
          alert('장바구니 버튼 테두리 색상을 입력해주세요.');
          return false;
        }
      } else if (this.cart.checked === false) {
        _set_button_param.cart.text = '';
        _set_button_param.cart.button_color = '';
        _set_button_param.cart.text_color = '';
        _set_button_param.cart.button_border_color = '';
      }

      if (this.more.checked === true) {
        if (this.more.text === '' || this.more.text.length === 0) {
          alert('자세히보기 버튼명을 입력해주세요.');
          return false;
        }
        if (
          this.more.button_color === '' ||
          this.more.button_color.length === 0
        ) {
          alert('자세히보기 버튼색상을 입력해주세요.');
          return false;
        }
        if (this.more.text_color === '' || this.more.text_color.length === 0) {
          alert('자세히보기 글자색상을 입력해주세요.');
          return false;
        }
        if (
          this.more.button_border_color === '' ||
          this.more.button_border_color.length === 0
        ) {
          alert('자세히보기 버튼 테두리 색상을 입력해주세요.');
          return false;
        }
      } else if (this.more.checked === false) {
        _set_button_param.more.text = '';
        _set_button_param.more.button_color = '';
        _set_button_param.more.text_color = '';
        _set_button_param.more.button_border_color = '';
      }

      if (this.delivery.checked === true) {
        if (this.delivery.text === '') {
          alert('정기결제(배송) 버튼명을 입력해주세요.');
          return false;
        } else if (this.delivery.button_color === '') {
          alert('정기결제(배송) 버튼색상을 입력해주세요.');
          return false;
        } else if (this.delivery.text_color === '') {
          alert('정기결제(배송) 글자색상을 입력해주세요.');
          return false;
        }
      } else if (this.delivery.checked === false) {
        _set_button_param.delivery.text = '';
        _set_button_param.delivery.button_color = '';
        _set_button_param.delivery.text_color = '';
      }

      // _set_button_param
      _param.button = _set_button_param;

      this.$emit('updateData', _param);
      this.show_modal = false;
    },
    onlyHtmlColor: function (event, key) {
      const value = event.target.value.replace(/[^#0-9a-zA-Z]/g, '');

      if (key === 'btnBgCartColor') this.cart.button_color = value;
      else if (key === 'btnTextCartColor') this.cart.text_color = value;
      else if (key === 'btnBgDtl') this.more.button_color = value;
      else if (key === 'btnTextDtl') this.more.text_color = value;
      else if (key === 'btnBgPay') this.delivery.button_color = value;
      else if (key === 'btnTextPay') this.delivery.text_color = value;

      event.target.value = value;
    },
    inputText: function (e) {
      let value = this.replaceHtml(e.target.value);
      if (e.target.id == 'basketSave') {
        this.cart.text = value;
      }
      if (e.target.id == 'more_text') {
        this.more.text = value;
      }
    },
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.layer_dialog#modalResultBtn {
  width: 1180px;
}

.tip_help {
  display: block;
}
.layer_dialog {
  padding-bottom: 35px;
}
</style>
